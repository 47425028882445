<template>
  <ModalGenerator
      :backend_errors="backend_errors"
      :data="entityData.data"
      :dictionaries="entityData.dictionaries"
      :formIds="entityData.tabs"
      :is-open="isModalOpened"
      :loading="entityData.loading"
      :module-name="moduleName"
      :saveOrUpdateLoading="saveOrUpdateLoading"
      :schema="entityData.schema"
      :schemaVersion="entityData.schemaVersion"
      :subModuleName="subModuleName"
      :type="entityData.type"
      @close="closeModal"
      @edit="openEditModal"
      @goToShow="openReadModal"
      @remove="remove"
      @saveOrUpdate="saveOrUpdate"
      headerTitlePath="base.name"
      idPath="base.id"
  />
</template>

<script>
import axios from 'axios';
import swal from 'sweetalert2';
import ModalGenerator from './modals/ModalGenerator.vue';
import notification from '../../notification/notify';
import EventBus from '../../eventBus';

export default {
  name: 'ModalGeneratorWrapper',
  components: {ModalGenerator},
  props: {
    moduleName: {
      type: String,
      required: true,
    },
    subModuleName: {
      type: String,
      required: true,
    },
    formId: {
      type: Number,
      required: true,
    },
    // isOpen: {
    //   type: Boolean,
    //   required: true
    // },
    value: {
      type: [Number, String],
      default: null,
    },
    overlayEntityId: {
      type: Number,
    },
    overlayEntityFormId: {
      type: Number,
    },
  },
  data() {
    return {
      isModalOpened: false,
      entityData: {
        data: null,
        schema: null,
        schemaVersion: null,
        dictionaries: null,
        tabs: [],
        type: 'read',
        loading: false,
      },
      backend_errors: {},
      saveOrUpdateLoading: false,
      oldFormData: {
        base: {},
        custom: {},
      },
    };
  },
  watch: {
    value(val) {
      if (val) {
        if (val === 'create') {
          this.openCreateModal();
        } else {
          this.openReadModal(val);
        }
      } else {
        this.isModalOpened = false;
      }
    },
  },
  methods: {
    closeModal() {
      this.opportunityData = {
        data: null,
        schema: null,
        tabs: [],
        schemaVersion: null,
        dictionaries: null,
        type: 'read',
        loading: false,
      };
      this.$emit('input', null);
    },
    async openModal(type, id = null) {
      this.backend_errors = {};
      this.isModalOpened = true;
      try {
        await this.fetchEntity(type, id);
      } catch (e) {
        this.closeModal();
        notification.notify(
            this.$t('notify.error'),
            this.$t('system.can_not_load'),
            'error');
      } finally {
        this.entityData.loading = false;
      }
    },
    openCreateModal() {
      this.openModal('create');
    },
    openReadModal(id) {
      this.openModal('read', id);
    },
    async saveOrUpdate(data, id) {
      this.saveOrUpdateLoading = true;
      const oldData = {...this.oldFormData.custom, ...this.oldFormData.base};
      const dataToSend = this.parseFields({...data.custom, ...data.base});
      Object.keys(dataToSend).forEach(key => {
        if (dataToSend[key] === oldData[key] || (this.existsFileUploadComponent() && key === this.subModuleName)) {
          delete dataToSend[key];
        }
      });
      const params = {
        ...dataToSend,
        form_id: this.formId,
        entity_id: this.overlayEntityId,
        entity_form_id: this.overlayEntityFormId,
      };
      try {
        const {data} = await axios.post(`${this.$route.params.moduleName || 'crm'}/${this.subModuleName}${id ? `/${id}` : ''}`, params);
        if (this.existsFileUploadComponent()) {
          EventBus.$emit('WAS_CREATED_OR_UPDATED', data && data.data && data.data.base && data.data.base.id ? data.data.base.id : id);
          const handleFileUpload = new Promise((resolve, reject) => {
            EventBus.$on('FILES_UPLOADED', () => {
              resolve();
            });
          });
          await handleFileUpload;
        }
        await this.closeModal();
        notification.notify(
            this.$t('notify.success'),
            this.$t(`system.${this.subModuleName}_${id ? 'updated' : 'saved'}`),
            'success');
      } catch (e) {
        this.saveOrUpdateLoading = false;
        if (e && e.response && e.response.data && e.response.data.errors) {
          this.backend_errors = e.response.data.errors;
        } else {
          console.log('error', e);
        }
      } finally {
        this.saveOrUpdateLoading = false;
      }
    },
    openEditModal(id) {
      this.backend_errors = {};
      this.fetchEntity('edit', id);
    },
    async remove(id) {
      const result = await swal.fire({
        target: this.$refs.root,
        title: this.$t('system.remove'),
        text: this.$t('system.if_you_sure'),
        type: 'info',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: this.$t('system.yes'),
        cancelButtonText: this.$t('system.no'),
      });
      if (result.value) {
        try {
          await axios.delete(`${this.$route.params.moduleName || 'crm'}/${this.subModuleName}/${id}`);
          this.closeModal();
          notification.notify(
              this.$t('notify.success'),
              this.$t(`system.${this.subModuleName}_deleted`),
              'success');
          this.fetchData();
        } catch (e) {
          notification.notify(
              this.$t('notify.error'),
              this.$t('system.can_not_load'),
              'error');
        }
      }
    },
    async fetchEntity(type = 'read', id = null) {
      let data = null;
      if (type === 'create') {
        data = await axios.get(`/${this.$route.params.moduleName || 'crm'}/${this.subModuleName}/create?form_id=${this.formId}`);
      } else if (type === 'read' && id) {
        data = await axios.get(`/${this.$route.params.moduleName || 'crm'}/${this.subModuleName}/${id}?form_id=${this.formId}`);
      } else if (type === 'edit') {
        data = await axios.get(`/${this.$route.params.moduleName || 'crm'}/${this.subModuleName}/${id}/edit?form_id=${this.formId}`);
      }
      data = data.data ? data.data : null;
      if (data && data.data && data.dictionaries && data.schema) {
        this.entityData.data = data.data;
        this.oldFormData.base = data && data.data && data.data.base
                                ? {...data.data.base}
                                : {};
        this.oldFormData.custom = data && data.data && data.data.custom ? {...data.data.custom} : {};
        this.entityData.dictionaries = data.dictionaries;
        this.entityData.schema = data.schema;
        this.entityData.schemaVersion = data.schemaVersion;
        this.entityData.tabs = data.relationships;
        this.entityData.type = type;
      }
    },
    // same fn is located in CRM.Index.vue
    parseFields(data) {
      const newData = {...data};
      Object.keys(this.entityData.schema).forEach(schemaPartName => {
        Object.keys(this.entityData.schema[schemaPartName]).forEach(container => {
          if (this.entityData.schema[schemaPartName][container].fields) {
            this.entityData.schema[schemaPartName][container].fields.forEach(field => {
              if (field.validation && field.validation.includes('numeric') && data[field.dbName]) {
                newData[field.dbName] = String(data[field.dbName]).replace(/,/gi, '.');
              } else if (field.inputType === 'checkbox' || field.type === 'switch') {
                newData[field.dbName] = !!data[field.dbName];
              }
            });
          }
        });
      });
      return newData;
    },
    // same fn is located in CRM.Index.vue
    existsFileUploadComponent() {
      let result = false;
      this.entityData.schema.containers.forEach((column) => {
        column.fields.forEach((item) => {
          if (item.inputType === 'files') {
            result = true;
          }
        });
      });
      return result;
    },
  },
};
</script>

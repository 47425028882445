<template>
  <div class="modalGenerator">
    <BaseModal
        :clickToClose="false"
        :delay="150"
        :name="modalName"
        :padding="padding"
        :show="isOpen"
        :width="width"
        @beforeClose="beforeClose"
        @beforeOpen="beforeOpen"
        height="auto"
        scrollable
        transition="el-fade-in"
        v-loading="loading"
    >
      <template
          slot="header"
          v-if="data && schema && dictionaries"
      >
        <div class="w-100 d-flex justify-content-between">
          <div class="modalGenerator--header--title">
            {{ type === 'create' ? $t('system.create') : getHeaderTitle() }}
          </div>
        </div>
      </template>
      <template slot="body">
        <!--        <el-tabs v-model="activeTab">-->
        <!--          <el-tab-pane :label="$t('system.general_info')" lazy name="first">-->

        <div
            class="container-fluid"
            style="padding-bottom: 1px;"
        >
          <el-menu
              :default-active="activeTab"
              @select="onSelectTab"
              class="el-menu-demo"
              mode="horizontal"
              v-if="relationships.length > 1"
          >
            <el-menu-item
                :index="tab.label"
                :key="idx"
                v-for="(tab, idx) in relationships"
            >
              {{ $t(`system.${tab.label.toLowerCase()}`) }}
            </el-menu-item>
          </el-menu>
          <el-form v-show="activeTab === 'general'">
            <div>
              <FormGenerator
                  :backend_errors="backend_errors"
                  :data="data"
                  :dictionaries="dictionaries"
                  :inputRequiredEmpty="inputRequiredEmpty"
                  :mode="type"
                  :schema="schema.menu"
                  :schemaVersion="schemaVersion"
                  v-if="schema && schema.menu"
              >
                <template slot="staticScope">
                  <div
                      class="col ml-auto pt-3"
                      v-if="type === 'read'"
                  >
                    <div class="d-flex justify-content-end align-items-center">
                      <el-button
                          @click.native="edit"
                          type="info"
                          v-if="ifHasPermission([`${moduleName.toLowerCase()}-${subModuleName.toLowerCase()}-edit`])"
                      >
                        {{ $t('issue.edit') }}
                      </el-button>
                      <el-dropdown
                          class="d-flex"
                          trigger="click"
                          v-if="ifHasPermission([`${moduleName.toLowerCase()}-${subModuleName.toLowerCase()}-delete`])"
                      >
                        <i
                            class="el-icon-more"
                            style="font-size: 24px; transform: rotate(90deg); cursor: pointer"
                        ></i>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item @click.native="remove">
                            {{ $t('issue.delete') }}
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                  </div>
                </template>
              </FormGenerator>
              <FormGenerator
                  :backend_errors="backend_errors"
                  :data="data"
                  :dictionaries="dictionaries"
                  :inputRequiredEmpty="inputRequiredEmpty"
                  :mode="type"
                  :schema="schema.containers"
                  :schemaVersion="schemaVersion"
                  v-if="schema && schema.containers"
              >
              </FormGenerator>
              <div
                  class="row"
                  v-if="type !== 'read'"
              >
                <div
                    :class="schema && schema.containers && schema.containers[schema.containers.length - 1] && schema.containers[schema.containers.length - 1].class ? schema.containers[schema.containers.length - 1].class : ''"
                    class="ml-auto"
                >
                  <div class="d-flex justify-content-end mb-3">
                    <el-button
                        :loading="saveOrUpdateLoading"
                        @click.prevent.stop="onSaveOrUpdate"
                        type="primary"
                    >
                      {{ type === 'create' ? $t('system.create') : $t('system.update') }}
                    </el-button>
                    <el-button
                        @click.native="goToShow"
                        v-if="type === 'edit'"
                    >{{ $t('system.cancel') }}
                    </el-button>
                    <el-button
                        @click.native="beforeClose"
                        v-else
                    >{{ $t('system.cancel') }}
                    </el-button>
                  </div>
                </div>
              </div>
            </div>
          </el-form>
          <ModuleIndex
              :moduleName="currentRelationship.module.name.toLowerCase()"
              :overlayEntityFormId="form.id"
              :overlayEntityId="id"
              :subModuleName="currentRelationship.submodule.name.toLowerCase()"
              isInsideComponent
              v-if="activeTab !== 'general'"
          />
        </div>
        <!--          </el-tab-pane>-->
        <!--          <el-tab-pane :label="$t('system.contacts')" lazy name="second">-->
        <!--            Contacts-->
        <!--          </el-tab-pane>-->
        <!--        </el-tabs>-->
      </template>
    </BaseModal>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import BaseModal from '../BaseModal.vue';
import FormGenerator from '../../crm-form-generator/FormsGenerator.vue';
import {getObjectValueByPath} from '../../../helpers/functions';

export default {
  name: 'ModalGenerator',
  components: {
    ModuleIndex: () => import('../../../views/CRM/Index.vue'),
    FormGenerator,
    BaseModal,
  },
  props: {
    form: {
      type: Object,
      default: () => {
        return {};
      },
    },
    width: {
      type: String,
      default: '90%',
    },
    padding: {
      type: String,
      default: '60px 0',
    },
    moduleName: {
      type: String,
      default: '',
    },
    subModuleName: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'read',
    },
    formIds: {
      type: Array,
      default: () => [],
    },
    schema: {
      type: Object,
      // required: true
    },
    schemaVersion: {
      type: String,
    },
    dictionaries: {
      type: Object,
      // required: true
    },
    data: {
      type: Object,
      // required: true
    },
    // idPath: {
    //   type: String,
    //   default: 'id'
    // },
    headerTitlePath: {
      type: String,
      default: 'name',
    },
    idPath: {
      type: String,
      default: 'id',
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    saveOrUpdateLoading: {
      type: Boolean,
      default: false,
    },
    backend_errors: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      // formIds: [3],
      relationships: [
        {label: 'general'},
      ],
      activeTab: 'general',
      // modalName: 'modalGenerator',
      inputRequiredEmpty: [],
      // backend_errors: {}
    };
  },
  computed: {
    ...mapState('modules', ['modules']),
    modalName() {
      return `modalGenerator_${Math.floor(Math.random() * 100000)}`;
    },
    currentRelationship() {
      return this.relationships.find(item => item.label === this.activeTab);
    },
    id() {
      return getObjectValueByPath(this.idPath, this.data);
    },
  },
  watch: {
    isOpen(value) {
      if (value) {
        this.$modal.show(this.modalName);
        this.activeTab = 'general';
        // this.generateTabs()
      } else {
        this.$modal.hide(this.modalName);
      }
      this.inputRequiredEmpty = [];
    },
    formIds() {
      this.generateTabs();
    },
  },
  // mounted() {
  //   this.generateTabs()
  // },
  methods: {
    generateTabs() {
      this.relationships = [{label: 'general'}];
      this.formIds.forEach(formId => {
        const path = this.findPathToForm(formId);
        if (path) {
          const relationshipIdx = this.relationships.findIndex(item => item.label === path.submodule.name);
          if (relationshipIdx < 0) {
            this.relationships.push({
              label: path.submodule.name,
              module: {
                id: path.module.id,
                name: path.module.name,
              },
              submodule: {
                id: path.submodule.id,
                name: path.submodule.name,
              },
              form: {
                id: path.form.id,
                name: path.form.name,
              },
            });
          }
        }
      });
    },
    findPathToForm(formId) {
      let submodule = null;
      let form = null;
      const module = this.modules.find(module => {
        if (module.submodules) {
          const moduleCandidate = module.submodules.find(submodule => {
            if (submodule.forms) {
              const formCandidate = submodule.forms.find(form => form.id === formId);
              if (formCandidate) {
                form = formCandidate;
                return true;
              }
              return false;
            }
          });
          if (moduleCandidate) {
            submodule = moduleCandidate;
            return true;
          }
          return false;
        }
      });
      if (module && submodule && form) {
        return {module, submodule, form};
      }
      return null;
    },
    onSelectTab(index, indexPath) {
      this.activeTab = index;
    },
    getHeaderTitle() {
      if (this.id) {
        return `#${this.id} ${getObjectValueByPath(this.headerTitlePath, this.data) ? getObjectValueByPath(this.headerTitlePath, this.data) : ''}`;
      }
    },
    // closeByEsc(e) {
    //   if (e.keyCode === 27) {
    //     this.beforeClose()
    //   }
    // },
    beforeOpen() {
      document.addEventListener('keyup', this.closeByEsc);
    },
    beforeClose() {
      // document.removeEventListener('keyup', this.closeByEsc);
      // console.log('beforeClose data', this.data)
      this.$emit('close', this.data && this.data.base && this.data.base.form_id ? this.data.base.form_id : null);
    },
    edit() {
      this.$emit('edit', this.id /*getObjectValueByPath(this.idPath, this.data)*/);
    },
    remove() {
      this.$emit('remove', this.id /*getObjectValueByPath(this.idPath, this.data)*/);
    },
    getFileUploadComponent() {
      let result = null;
      this.schema.containers.forEach((column) => {
        column.fields.forEach((item) => {
          if (item.inputType === 'files') {
            result = item;
          }
        });
      });
      return result;
    },
    onSaveOrUpdate() {
      const fileComponent = this.getFileUploadComponent();
      const requiredItemsNames = [];
      if (fileComponent && fileComponent.validation && fileComponent.validation.includes('required')) {
        const valueFromBase = getObjectValueByPath(`base.${fileComponent.dbName}`, this.data);
        const valueFromCustom = getObjectValueByPath(`custom.${fileComponent.dbName}`, this.data);
        if (valueFromBase || valueFromCustom) {
          if ((valueFromBase && !valueFromBase.length) || (valueFromCustom && !valueFromCustom.length)) {
            requiredItemsNames.push(fileComponent.dbName);
          }
        }
      }
      this.checkValidation(requiredItemsNames).then(() => {
        this.$emit('saveOrUpdate', this.data, this.id);
      });
    },
    goToShow() {
      this.$emit('goToShow', this.id /*getObjectValueByPath(this.idPath, this.data)*/);
    },
  },
};
</script>

<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
.el-menu {
  .el-menu-item {
    text-align: center;
    padding: 0 20px !important;
    height: 50px !important;
  }
}

.modalGenerator--header--title {
  color: #fff;
  font-weight: 600;
  font-size: 18px;
}

.modalGenerator--header--subTitle {
  color: #bdbdbd;
}
</style>

<style
    lang="scss"
    rel="stylesheet/scss"
>
.modalGenerator {
  .view-container {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;

    .view-body {
      padding: 15px 0 0 0 !important;
    }
  }

  .el-tabs__header {
    margin: 0;

    .el-tabs__nav {
      margin: 0 15px
    }
  }
}
</style>
